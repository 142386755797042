<template>
    <section class="pt-8">
        <div class="container full-height p-4">
            <div class="row">
                <div class="col-12 col-lg-5  col-md-6">
                    <div class="card auth-card px-5 py-7">
                        <template>
                            <div class="text-center fa-2x text-success mb-4">
                                <success-icon/>
                            </div>
                        </template>
                        <login-heading class="mb-4" heading="Registration Successful">
                        </login-heading>
                        <p class="text-white font-inter-extra-light mb-5">Congratulations your account has been successfully registered at Bank e Auctions India.com</p>
                        <btn block text="Back to Login" @click="$router.push({name:'Login'})" class="mt-4 border-r--2 auth-button py-3"></btn>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'RegistrationSuccessful',
    components: { LoginHeading }
};
</script>

<style scoped>

</style>
